import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../../hooks/useDebounce';
import { PickListInfo } from '../../../models/Picklist';
import PickListService from '../../../services/PickListService';
import PicklistEditor from '../../../components/picklist-builder/PicklistEditor';
import { nextTick } from '../../../utils/ReactUtils';
import { useCurrentTenantId } from '../../../global-state/Clients';
import { useTranslation } from 'react-i18next';
import { Translations } from '../../../models/Translation';

type PicklistBuilderProps = {
  clientId?: string;
};

const PicklistBuilder: FC<PicklistBuilderProps> = (props) => {
  const { clientId } = props;
  const currentTenantId = useCurrentTenantId((x) => x.value);

  const [picklists, setPicklists] = useState<PickListInfo[]>([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 500);
  const [pageNumber, setPageNumber] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const getPicklists = useCallback(() => {
    const scrollTop = window.scrollY;
    setLoading(true);

    PickListService.getAll({
      pageSize: 15,
      pageNumber,
      searchTerm: debouncedSearchPhrase,
      includeArchived: true,
      searchLanguage: i18n.language,
      clientId,
      excludeClientless: !!clientId,
    }).then((res) => {
      setPicklists((prev) => {
        return [...(pageNumber > 1 ? prev : []), ...res.data];
      });
      setShowLoadMore(res.hasNextPage || false);
      setLoading(false);

      nextTick(() => {
        window.scrollTo({ top: scrollTop });
      });
    });
  }, [clientId, debouncedSearchPhrase, i18n.language, pageNumber]);

  useEffect(() => {
    getPicklists();
  }, [getPicklists]);

  const onArchive = (code: string) => {
    PickListService.archive(code).then(() => {
      getPicklists();
    });
  };

  const onUnarchive = (code: string) => {
    PickListService.unarchive(code).then(() => {
      getPicklists();
    });
  };

  const onAdd = (translations: Translations<'name' | 'description'>, isSystem: boolean) => {
    PickListService.create({ isSystem, translations, clientId }).then((res) => {
      const path = clientId ? `/clients/${clientId}/picklists/${res.data.id}` : `/builder/${currentTenantId}/picklists/${res.data.id}`;
      navigate(path);
    });
  };

  const onSearchPhraseChange = useCallback((value: string) => {
    setPageNumber(1);
    setSearchPhrase(value);
  }, []);

  const loadMore = useCallback(() => {
    setPageNumber((prev) => prev + 1);
  }, []);

  const changePicklistType = useCallback((isSystem: boolean, picklist: PickListInfo) => {
    PickListService.update({ ...picklist, isSystem }).then(() => {
      setPicklists((prev) => prev.map((x) => (x.id === picklist.id ? { ...x, isSystem } : x)));
    });
  }, []);

  const onEdit = useCallback(
    (picklist: PickListInfo) => {
      setLoading(true);
      PickListService.update(picklist).then(() => {
        getPicklists();
      });
    },
    [getPicklists],
  );

  return (
    <PicklistEditor
      clientId={clientId}
      onEdit={onEdit}
      showLoadMore={showLoadMore}
      searchPhrase={searchPhrase}
      picklists={picklists}
      onArchive={onArchive}
      onAdd={(translations) => onAdd(translations, true)}
      onSearchPhraseChange={onSearchPhraseChange}
      isLoading={loading}
      onLoadMore={loadMore}
      changeType={changePicklistType}
      onUnarchive={onUnarchive}
    />
  );
};

export default PicklistBuilder;
